<template>

  <!-- Opp Table Widget -->
  <j-table
    :listing="opps"
    items="opportunities"
    :heading="heading"
    :subhead="subhead"
    :short-pagination="shortPagination"
    :show-pagination="showPagination"
    class="opp-table-widget"
    @paginate="handlePaginate"
  >
    <!-- Header -->
    <template
      v-if="showTableHeader"
      #header
    >
      <tr>
        <th v-if="show('date')">
          {{ dateLabel }}
        </th>
        <th v-if="show('section')">
          Section
        </th>
        <th width="90%">
          Title
        </th>
        <th v-if="show('stage')">
          Stage
        </th>
        <th v-else>&nbsp;</th>
        <th>&nbsp;</th>
      </tr>
    </template>

    <!-- Listing -->
    <template #listing>
      <tr
        v-for="opp in opps.getModels()"
        :key="opp.tag19"
      >

        <!-- Date -->
        <td v-if="show('date')" style="white-space: nowrap;">
          {{ opp[dateField] | shortDate }}
        </td>

        <!-- Section -->
        <td v-if="show('section')">
          <j-label :section="opp.section" />
        </td>

        <!-- Title -->
        <td width="70%">
          <router-link :to="{ name: 'opps-details', params: { tag19: opp.tag19 }}">
            <strong :title="opp.title">{{ opp.title | truncate(50) }}</strong>
          </router-link>
        </td>

        <!-- Stage -->
        <td v-if="show('stage')">
          {{ opp.stage | capitalize }}
        </td>

        <!-- Request -->
        <td v-if="show('request') && opp.stage == 'available' ">
          <OppRequest
            :opp="opp"
            button-type="icon"
          />
        </td>
        <td v-else>&nbsp;</td>
      </tr>
    </template>

    <!-- Footer -->
    <template v-if="$slots.footer" #footer>
      <tr>
        <td :colspan="columnCount">
          <slot name="footer" />
        </td>
      </tr>
    </template>

  </j-table>
</template>

<script>

// Components
import OppRequest from '@/components/widgets/Opps/OppRequest'

export default {
  name: 'OppTableWidget',
  // -------------
  // Components ==
  // -------------
  components: {
    OppRequest
  },
  // -------------
  // Properties ==
  // -------------
  props: {
    opps: {
      type: Object,
      default: () => {}
    },
    heading: {
      type: String,
      default: ''
    },
    subhead: {
      type: String,
      default: ''
    },
    hide: {
      type: Array,
      default: () => []
    },
    dateLabel: {
      type: String,
      default: 'Published'
    },
    dateField: {
      type: String,
      default: 'published_at'
    },
    shortPagination: {
      type: Boolean,
      default: false
    },
    showTableHeader: {
      type: Boolean,
      default: true
    },
    showPagination: {
      type: Boolean,
      default: true
    }
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    // UI
    columnCount () {
      return 4 - this.hide.length
    }
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    // UI
    show(column) {
      if (this.hide) {
        return !this.hide.includes(column)
      } else {
        return true
      }
    },
    // Handlers
    handlePaginate(page) {
      this.$emit('paginate', page)
    }
  }
}
</script>
