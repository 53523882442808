<template>

  <!-- Opp Request Widget (Button) -->
  <div
    v-if="showButton"
    class="opp-request-widget"
  >

    <!-- Cancel Request Button -->
    <j-button
      v-if="opp.requested"
      label="Cancel Request"
      type="secondary"
      icon="star"
      :processing="opp.saving"
      @action="handleUnrequest"
    />

    <!-- Request Button -->
    <j-button
      v-else
      label="Request"
      icon="star"
      :processing="opp.saving"
      @action="handleRequest"
    />
  </div>

  <!-- Opp Request Widget (Icon) -->
  <div
    v-else
    class="opp-request-widget"
  >

    <!-- Cancel Request Icon -->
    <j-icon
      v-if="opp.requested"
      class="primary--text"
      icon="star"
      @click.stop="handleUnrequest"
    />

    <!-- Request Icon -->
    <j-icon
      v-else
      class="secondary--text"
      icon="star"
      @click="handleRequest"
    />

  </div>
</template>

<script>

export default {
  name: 'OppRequestWidget',
  // -------------
  // Properties ==
  // -------------
  props: {
    opp: {
      type: Object,
      default: () => {}
    },
    buttonType: {
      type: String,
      default: 'button'
    }
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    // UI
    showButton () {
      return this.buttonType === 'button'
    }
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    // Handlers
    handleRequest() {
      this.opp.makeRequest()
    },
    handleUnrequest() {
      this.opp.cancelRequest()
    }
  }
}
</script>
